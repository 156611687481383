import { ClassData } from '@models/class-data';
import { EventTicket } from './event-ticket.model';
import { EventAttendeeTicketOrder } from './event-attendee-ticket-order.model';

export class EventAttendeeTicket {
  id: number;
  createdAt?: Date;

  uuid: string;

  ticketNumber: string;

  eventAttendeeTicketOrderId: number;
  eventAttendeeTicketOrder?: EventAttendeeTicketOrder;
  eventTicketId: number;
  eventTicket?: EventTicket;

  basePrice: number;
  price: number;

  isUsed: boolean;
  usedDate?: Date;

  constructor(json: ClassData<EventAttendeeTicket>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.uuid = json.uuid;
    this.ticketNumber = json.ticketNumber;

    this.eventAttendeeTicketOrderId = +json.eventAttendeeTicketOrderId;
    if (json.eventAttendeeTicketOrder) {
      this.eventAttendeeTicketOrder = new EventAttendeeTicketOrder(
        json.eventAttendeeTicketOrder,
      );
    }
    this.eventTicketId = +json.eventTicketId;
    if (json.eventTicket) {
      this.eventTicket = new EventTicket(json.eventTicket);
    }

    this.basePrice = json.basePrice;
    this.price = json.price;

    this.isUsed = json.isUsed;
    if (json.usedDate) {
      this.usedDate = new Date(json.usedDate);
    }
  }
}
