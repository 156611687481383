import { ClassData } from '@models/class-data';
import { TicketFeeType } from '@models/tickets/ticket-fee-type.enum';
import { TicketSalesChannel } from '@models/tickets/ticket-sales-channel.enum';
import { TicketType } from '@models/tickets/ticket-type.enums';
import { NumberUtils } from '@utils/number-utils';

export class EventTicket {
  id?: number;
  eventId: number;
  type: TicketType;
  name: string;
  description: string;
  maxCapacity: number;
  feeType: TicketFeeType;
  price: number;
  saleStartDate?: Date | null;
  saleEndDate?: Date | null;

  day?: Date | null;

  visible: boolean;
  showSalesDeadlineAndStatusDuringOrder: boolean;
  minAmountPerOrder: number;
  maxAmountPerOrder: number;
  salesChannel: TicketSalesChannel;
  link: string;
  numSoldTickets?: number;
  numLockedTickets?: number;

  constructor(json: ClassData<EventTicket>) {
    this.id = json.id;
    this.eventId = json.eventId;
    this.type = json.type;
    this.name = json.name;
    this.description = json.description;
    this.maxCapacity = json.maxCapacity;
    this.feeType = json.feeType;
    this.price = json.price;
    if (json.saleStartDate) {
      this.saleStartDate = new Date(json.saleStartDate);
    }
    if (json.saleEndDate) {
      this.saleEndDate = new Date(json.saleEndDate);
    }

    if (json.day) {
      this.day = new Date(json.day);
    }

    this.visible = json.visible;
    this.showSalesDeadlineAndStatusDuringOrder =
      json.showSalesDeadlineAndStatusDuringOrder;
    this.minAmountPerOrder = json.minAmountPerOrder;
    this.maxAmountPerOrder = json.maxAmountPerOrder;
    this.salesChannel = json.salesChannel;
    this.link = json.link;
    this.numSoldTickets = json.numSoldTickets;
    this.numLockedTickets = json.numLockedTickets;
  }

  getTicketStatusDisplayName(): string {
    const now = new Date();

    if (
      this.maxCapacity &&
      this.numSoldTickets &&
      this.numSoldTickets >= this.maxCapacity
    ) {
      return 'APP.TICKETS_EDIT.SOLD_OUT';
    }

    if (this.saleStartDate && now < this.saleStartDate) {
      return 'APP.TICKETS_EDIT.COMING_SOON';
    } else if (this.saleEndDate && now > this.saleEndDate) {
      return 'APP.TICKETS_EDIT.SALE_ENDED';
    }

    return 'APP.TICKETS_EDIT.ON_SALE';
  }

  getPriceFormatted(): string {
    if (this.price === undefined) {
      return '';
    }
    return (this.price / 100.0).toFixed(2);
  }

  getDisplayPrice(): string {
    return `${NumberUtils.roundToUpTo2Decimals(this.price / 100.0)} €`;
  }

  getNumAvailableTickets(): number {
    let availableTickets = this.maxCapacity;

    if (this.numSoldTickets) {
      availableTickets -= this.numSoldTickets;
    }
    if (this.numLockedTickets) {
      availableTickets -= this.numLockedTickets;
    }

    return availableTickets ? availableTickets : 0;
  }
}
