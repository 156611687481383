import { ClassData } from '@models/class-data';
import { User } from '@models/users/user.model';
import { EventAttendeeStatus } from './event-attendee-status.enum';
import { RSVPOptionType } from './rsvp-option-type.enum';
import { EventAttendeeQuestionAnswer } from './event-attendee-question-answer.model';
import { EventAttendeeQuestion } from './event-attendee-question.model';

export class EventAttendee {
  id: number;
  createdAt?: Date;
  updatedAt?: Date;

  groupLeadAttendeeId?: number;

  userId?: number;
  user?: User;
  // used by admin
  userFull?: User;

  groupName?: string;

  // plus one attendee info
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;

  eventId: number;

  status: EventAttendeeStatus;

  rsvpType: RSVPOptionType;
  rsvpDate: Date;

  numAttendees: number;

  hasPaid: boolean;

  message?: string;

  checkedIn?: boolean;

  groupMembers?: EventAttendee[];

  questionsAnswers?: EventAttendeeQuestionAnswer[];

  constructor(json: ClassData<EventAttendee>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.updatedAt) {
      this.updatedAt = new Date(json.updatedAt);
    }

    if (json.groupLeadAttendeeId) {
      this.groupLeadAttendeeId = +json.groupLeadAttendeeId;
    }

    if (json.userId) {
      this.userId = +json.userId;
    }
    if (json.user) {
      this.user = new User(json.user);
    }
    if (json.userFull) {
      this.userFull = new User(json.userFull);
    }

    this.groupName = json.groupName;

    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.email = json.email;
    this.phone = json.phone;

    this.eventId = +json.eventId;

    this.status = json.status;

    this.rsvpType = json.rsvpType;
    this.rsvpDate = new Date(json.rsvpDate);

    this.numAttendees = +json.numAttendees;

    this.hasPaid = json.hasPaid;

    this.message = json.message;

    this.checkedIn = !!json.checkedIn;

    if (json.groupMembers) {
      this.groupMembers = this.mapGroupMembers(json.groupMembers);
    }

    if (json.questionsAnswers) {
      this.questionsAnswers = this.mapQuestionsAnswers(json.questionsAnswers);
    }
  }

  getName(): string {
    if (!this.user && !this.userFull) {
      if (
        this.firstName &&
        this.firstName !== '' &&
        this.lastName &&
        this.lastName !== ''
      ) {
        return `${this.firstName} ${this.lastName}`;
      } else if (this.firstName && this.firstName !== '') {
        return this.firstName;
      } else if (this.lastName && this.lastName !== '') {
        return this.lastName;
      }
      return '';
    } else if (this.userFull) {
      return this.userFull.name;
    } else if (this.user) {
      return this.user.name;
    }

    return '';
  }

  getEmail(emptyValue = '-'): string {
    if (!this.userFull) {
      return this.email || emptyValue;
    }
    return this.userFull.email;
  }

  getPhone(emptyValue = '-'): string {
    if (!this.userFull) {
      return this.phone || emptyValue;
    }
    return this.userFull.phone;
  }

  getFirstName(): string {
    if (!this.user) {
      if (this.firstName && this.firstName !== '') {
        return this.firstName;
      }
      return '';
    }
    return this.user.getFirstName();
  }

  getLastName(): string {
    if (!this.user) {
      if (this.lastName && this.lastName !== '') {
        return this.lastName;
      }
      return '';
    }
    return this.user.getLastName();
  }

  getPlusOnes(): number {
    return Math.max(0, this.numAttendees - 1);
  }

  getFallbackInitials(): string {
    if (this.getName() !== '') {
      const nameParts = this.getName().trim().split(/\s+/);

      if (nameParts.length > 1) {
        return nameParts[0][0] + nameParts[1][0];
      }

      return nameParts[0][0];
    } else if (
      this.getEmail() &&
      this.getEmail() !== '' &&
      this.getEmail() !== '-'
    ) {
      return this.getEmail().trim()[0];
    }

    return '';
  }

  isNonPending(): boolean {
    return [
      EventAttendeeStatus.ATTENDING,
      EventAttendeeStatus.MAYBE_ATTENDING,
      EventAttendeeStatus.NOT_ATTENDING,
    ].includes(this.status);
  }

  isNonPendingActive(): boolean {
    return [
      EventAttendeeStatus.ATTENDING,
      EventAttendeeStatus.MAYBE_ATTENDING,
    ].includes(this.status);
  }

  getQuestionAnswerDisplay(question: EventAttendeeQuestion): string {
    if (!this.questionsAnswers) {
      return '-';
    }

    const answer = this.questionsAnswers.find(
      (answer) => answer.eventAttendeeQuestionId === question.id,
    );
    if (!answer) {
      return '-';
    }

    if (answer.openAnswer && answer.openAnswer !== '') {
      return answer.openAnswer;
    } else if (answer.boolAnswer !== undefined) {
      return answer.boolAnswer ? 'APP.YES' : 'APP.NO';
    } else if (answer.eventAttendeeQuestionOptionId) {
      const optionlabel = question.options?.find(
        (option) => option.id === answer.eventAttendeeQuestionOptionId,
      )?.label;
      return optionlabel || '';
    }

    return '-';
  }

  getQuestionAnswer(question: EventAttendeeQuestion): any {
    if (!this.questionsAnswers) {
      return undefined;
    }

    const answer = this.questionsAnswers.find(
      (answer) => answer.eventAttendeeQuestionId === question.id,
    );
    if (!answer) {
      return undefined;
    }

    if (answer.openAnswer && answer.openAnswer !== '') {
      return answer.openAnswer;
    } else if (answer.boolAnswer !== undefined) {
      return answer.boolAnswer;
    } else if (answer.eventAttendeeQuestionOptionId) {
      return answer.eventAttendeeQuestionOptionId;
    }

    return undefined;
  }

  private mapGroupMembers(groupMembers: EventAttendee[]): EventAttendee[] {
    return groupMembers.map((it) => new EventAttendee(it));
  }

  private mapQuestionsAnswers(
    questionsAnswers: EventAttendeeQuestionAnswer[],
  ): EventAttendeeQuestionAnswer[] {
    return questionsAnswers.map((it) => new EventAttendeeQuestionAnswer(it));
  }
}
